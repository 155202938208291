import React, { Component } from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

//let mapkey = 'pk.eyJ1IjoiY2FyZS1yZXNlYXJjaCIsImEiOiJjbDVhNGh3MjMwNTk1M2duMDNyamgyc2JxIn0.kA6mqnKh9t1ybIcIEfFNiQ'
let mapkey = 'pk.eyJ1IjoiY2FyZS1yZXNlYXJjaCIsImEiOiJja2hmYTljencwcTJzMnVwajg5NmVhcWxoIn0.7xk1oRyJq2ekckshC4h62g'
if (process.env.GATSBY_MAPBOX_KEY) {
  mapkey = process.env.GATSBY_MAPBOX_KEY
}

const Map = ReactMapboxGl({
    accessToken: mapkey,
});

class MapBox extends Component {
  constructor(props) {
    super(props)
    
  }
  render() {
    const {locations} = this.props

    return (
        <Map
            center={[parseFloat(locations[0].lat), parseFloat(locations[0].lng)]}
            zoom={[14]}
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{ height: '30vh', width: '80%' }}>
            <Layer type="symbol" id="marker" layout={{ 'icon-image': 'doctor-15', 'icon-size': 2 }}>
                <Feature coordinates={[parseFloat(locations[0].lat), parseFloat(locations[0].lng)]} />
            </Layer>
        </Map>
    )
  }
}

export default MapBox;